import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Plus, Trash2, Upload } from "lucide-react";

// Compression utilities
const compressImage = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Calculate new dimensions (max width/height of 1920px while maintaining aspect ratio)
        let width = img.width;
        let height = img.height;
        const maxDimension = 1920;

        if (width > height && width > maxDimension) {
          height = (height * maxDimension) / width;
          width = maxDimension;
        } else if (height > maxDimension) {
          width = (width * maxDimension) / height;
          height = maxDimension;
        }

        canvas.width = width;
        canvas.height = height;

        // Draw and compress
        ctx.drawImage(img, 0, 0, width, height);

        // Convert to WebP if supported, otherwise fall back to JPEG
        const quality = 0.8;
        const mimeType = "image/webp";

        canvas.toBlob(
          (blob) => {
            if (blob) {
              // Create a new file with the compressed data
              const compressedFile = new File(
                [blob],
                file.name.replace(/\.[^/.]+$/, ".webp"),
                {
                  type: mimeType,
                }
              );
              resolve(compressedFile);
            } else {
              resolve(file); // Fallback to original if compression fails
            }
          },
          mimeType,
          quality
        );
      };
    };
  });
};

const MAX_VIDEO_SIZE = 100 * 1024 * 1024; // 100MB
const MAX_VIDEO_DIMENSION = 3840; // 4K resolution

const validateVideo = async (file) => {
  return new Promise((resolve, reject) => {
    // Check file size
    if (file.size > MAX_VIDEO_SIZE) {
      reject(
        new Error(
          `Video file must be smaller than ${MAX_VIDEO_SIZE / (1024 * 1024)}MB`
        )
      );
      return;
    }

    // Check if it's a valid video file
    const video = document.createElement("video");
    video.preload = "metadata";

    video.onloadedmetadata = () => {
      URL.revokeObjectURL(video.src);
      console.log("Video metadata loaded:", {
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
      });

      // Check video dimensions (allow up to 4K)
      if (
        video.videoWidth > MAX_VIDEO_DIMENSION ||
        video.videoHeight > MAX_VIDEO_DIMENSION
      ) {
        reject(
          new Error(
            `Video dimensions must not exceed ${MAX_VIDEO_DIMENSION}x${MAX_VIDEO_DIMENSION} (4K)`
          )
        );
        return;
      }

      // Check duration (optional)
      if (video.duration > 300) {
        // 5 minutes
        reject(new Error("Video must not exceed 5 minutes"));
        return;
      }

      resolve(file);
    };

    video.onerror = () => {
      const error = video.error;
      URL.revokeObjectURL(video.src);
      console.error("Video loading error:", error);
      reject(
        new Error(
          error ? `Invalid video file: ${error.message}` : "Invalid video file"
        )
      );
    };

    const objectUrl = URL.createObjectURL(file);
    console.log("Created object URL for video:", objectUrl);
    video.src = objectUrl;
  });
};

const AdditionalVideosSection = ({
  section,
  handleSectionChange,
  fileInputRef,
  renderUploadButton,
  handleMediaUpload,
}) => {
  const [isCompressing, setIsCompressing] = useState({});
  const [uploadError, setUploadError] = useState({});

  // Debug log for props and refs
  useEffect(() => {
    console.log("Current fileInputRef:", fileInputRef.current);
  }, [fileInputRef.current]);

  // Initialize refs if they don't exist
  const ensureRef = (refKey) => {
    console.log("Ensuring ref for:", refKey);
    if (!fileInputRef.current[refKey]) {
      fileInputRef.current[refKey] = null;
    }
  };

  const handleFileUpload = async (
    file,
    sectionType,
    field,
    index,
    updateField
  ) => {
    console.log("handleFileUpload called with:", {
      file,
      sectionType,
      field,
      index,
      updateField,
    });
    try {
      setUploadError((prev) => ({ ...prev, [`${field}-${index}`]: null }));
      setIsCompressing((prev) => ({ ...prev, [`${field}-${index}`]: true }));

      let processedFile;
      if (file.type.startsWith("image/")) {
        processedFile = await compressImage(file);
      } else if (file.type.startsWith("video/")) {
        try {
          processedFile = await validateVideo(file);
        } catch (error) {
          console.error("Video validation error:", error);
          setUploadError((prev) => ({
            ...prev,
            [`${field}-${index}`]: error.message,
          }));
          return;
        }
      } else {
        processedFile = file;
      }

      console.log("Processing complete, uploading file...");
      const url = await handleMediaUpload(sectionType, field, processedFile);
      console.log("Upload complete, URL:", url);

      if (url) {
        const updatedVideos = [...section.data.videos];
        updatedVideos[index] = {
          ...section.data.videos[index],
          [updateField]: url,
        };
        handleSectionChange("additionalVideos", "videos", updatedVideos);
      }
    } catch (error) {
      console.error(`Error processing ${field}:`, error);
      setUploadError((prev) => ({
        ...prev,
        [`${field}-${index}`]: "Failed to process file",
      }));
    } finally {
      setIsCompressing((prev) => ({ ...prev, [`${field}-${index}`]: false }));
    }
  };

  const triggerFileInput = (refKey) => {
    console.log("Triggering file input for:", refKey);
    console.log("Current ref:", fileInputRef.current[refKey]);
    if (fileInputRef.current[refKey]) {
      fileInputRef.current[refKey].click();
    } else {
      console.error("File input ref not found:", refKey);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-sm font-medium text-white/70">Additional Videos</h3>
        <motion.button
          type="button"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          onClick={() => {
            const currentVideos = section.data.videos || [];
            handleSectionChange("additionalVideos", "videos", [
              ...currentVideos,
              { videoUrl: "", posterImage: "", title: "", description: "" },
            ]);
          }}
          className="px-3 py-1 rounded-full bg-primary-green/20 text-primary-green text-sm flex items-center gap-2"
        >
          <Plus className="w-4 h-4" />
          Add Video
        </motion.button>
      </div>

      {section.data.videos &&
        section.data.videos.map((video, index) => {
          const videoRefKey = `additionalVideos-video-${index}`;
          const posterRefKey = `additionalVideos-poster-${index}`;

          // Ensure refs exist
          ensureRef(videoRefKey);
          ensureRef(posterRefKey);

          return (
            <div key={index} className="bg-white/5 rounded-lg p-6 space-y-4">
              <div className="flex justify-between items-start">
                <h4 className="text-white font-medium">Video {index + 1}</h4>
                <motion.button
                  type="button"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => {
                    const updatedVideos = section.data.videos.filter(
                      (_, i) => i !== index
                    );
                    handleSectionChange(
                      "additionalVideos",
                      "videos",
                      updatedVideos
                    );
                  }}
                  className="p-2 rounded-full bg-white/5 hover:bg-white/10 text-white"
                >
                  <Trash2 className="w-4 h-4" />
                </motion.button>
              </div>

              {/* Video Title */}
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  Title
                </label>
                <input
                  type="text"
                  value={video.title || ""}
                  onChange={(e) => {
                    const updatedVideos = [...section.data.videos];
                    updatedVideos[index] = {
                      ...video,
                      title: e.target.value,
                    };
                    handleSectionChange(
                      "additionalVideos",
                      "videos",
                      updatedVideos
                    );
                  }}
                  className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                  placeholder="Enter video title"
                />
              </div>

              {/* Video Description */}
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  Description
                </label>
                <textarea
                  value={video.description || ""}
                  onChange={(e) => {
                    const updatedVideos = [...section.data.videos];
                    updatedVideos[index] = {
                      ...video,
                      description: e.target.value,
                    };
                    handleSectionChange(
                      "additionalVideos",
                      "videos",
                      updatedVideos
                    );
                  }}
                  className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white min-h-[100px]"
                  placeholder="Enter video description"
                />
              </div>

              {/* Video URL */}
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  Video {isCompressing[`video-${index}`] && "(Validating...)"}
                </label>
                <div className="flex gap-4 flex-col">
                  <div className="flex gap-4">
                    <input
                      type="text"
                      value={video.videoUrl || ""}
                      onChange={(e) => {
                        const updatedVideos = [...section.data.videos];
                        updatedVideos[index] = {
                          ...video,
                          videoUrl: e.target.value,
                        };
                        handleSectionChange(
                          "additionalVideos",
                          "videos",
                          updatedVideos
                        );
                      }}
                      className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                      placeholder="Enter video URL or upload file"
                    />
                    <input
                      type="file"
                      ref={(el) => (fileInputRef.current[videoRefKey] = el)}
                      onChange={(e) => {
                        console.log("File input change event:", e.target.files);
                        if (e.target.files?.[0]) {
                          handleFileUpload(
                            e.target.files[0],
                            "additionalVideos",
                            `video-${index}`,
                            index,
                            "videoUrl"
                          );
                        }
                      }}
                      className="hidden"
                      accept="video/*"
                      disabled={isCompressing[`video-${index}`]}
                    />
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => triggerFileInput(videoRefKey)}
                      className="p-2 rounded-full bg-primary-green/20 text-primary-green relative overflow-hidden"
                      disabled={isCompressing[`video-${index}`]}
                    >
                      {isCompressing[`video-${index}`] ? (
                        <span className="relative z-10">Processing...</span>
                      ) : (
                        <Upload className="w-4 h-4" />
                      )}
                    </motion.button>
                  </div>
                  {uploadError[`video-${index}`] && (
                    <p className="text-red-500 text-sm mt-1">
                      {uploadError[`video-${index}`]}
                    </p>
                  )}
                  <p className="text-white/50 text-xs">
                    Maximum video size: 100MB. Maximum resolution: 1920x1080.
                    Maximum duration: 5 minutes.
                  </p>
                </div>
              </div>

              {/* Poster Image */}
              <div>
                <label className="block text-sm font-medium text-white/70 mb-2">
                  Poster Image{" "}
                  {isCompressing[`poster-${index}`] && "(Compressing...)"}
                </label>
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={video.posterImage || ""}
                    onChange={(e) => {
                      const updatedVideos = [...section.data.videos];
                      updatedVideos[index] = {
                        ...video,
                        posterImage: e.target.value,
                      };
                      handleSectionChange(
                        "additionalVideos",
                        "videos",
                        updatedVideos
                      );
                    }}
                    className="flex-1 bg-white/5 border border-white/10 rounded-lg px-4 py-2 text-white"
                    placeholder="Enter poster image URL or upload file"
                  />
                  <input
                    type="file"
                    ref={(el) => (fileInputRef.current[posterRefKey] = el)}
                    onChange={(e) => {
                      if (e.target.files?.[0]) {
                        handleFileUpload(
                          e.target.files[0],
                          "additionalVideos",
                          `poster-${index}`,
                          index,
                          "posterImage"
                        );
                      }
                    }}
                    className="hidden"
                    accept="image/*"
                    disabled={isCompressing[`poster-${index}`]}
                  />
                  {renderUploadButton("additionalVideos", `poster-${index}`)}
                </div>
              </div>
            </div>
          );
        })}

      {(!section.data.videos || section.data.videos.length === 0) && (
        <p className="text-white/50 text-sm italic text-center py-8">
          No additional videos added yet
        </p>
      )}
    </div>
  );
};

export default AdditionalVideosSection;
